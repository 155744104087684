export const publicRoutes = {
  support: {
    index: { pathname: '/support', access: true },
    howToSetupCdn: {
      pathname:
        '/support/knowledgebase/introduction-to-cdn/getting-started#first-cdn-configuration',
      access: true,
    },
    uploadingFiles: {
      pathname: '/support/knowledgebase/cdn77-object-storage/interacting-with-cdn77-object-storage',
      access: true,
    },
    knowledgebase: { pathname: '/support/knowledgebase', access: true },
    api: { pathname: '/support/api-reference/v3', access: true },
    internalApi: { pathname: '/support/api-reference/internal', access: true },
    status: { pathname: '/support/status', access: true },
    apiPurge: { pathname: '/support/api-reference/v3/jobs/purge', access: true },
    apiPrefetch: { pathname: '/support/api-reference/v3/jobs/prefetch', access: true },
    cdnStorage: {
      pathname: '/support/knowledgebase/cdn77-object-storage/cdn77-object-storage-introduction',
      access: true,
    },
    sniCertificate: {
      pathname: '/support/knowledgebase/security-measure/ssl-tls#sni-certificate',
      access: true,
    },
    objectStorageCyberduck: {
      pathname:
        '/support/knowledgebase/cdn77-object-storage/how-to-use-cyberduck-with-cdn77-object-storage',
      access: true,
    },
    caching: {
      pathname:
        '/support/knowledgebase/introduction-to-cdn/top-mistakes-when-setting-up-a-cdn#cache-hit-ratio',
      access: true,
    },
  },
  customer: {
    join: { pathname: '/customer/join', access: true },
    accountDeactivated: { pathname: '/account-deactivated', access: true },
  },
  auth: {
    login: { pathname: '/login', access: true },
    resetPassword: { pathname: '/reset-password', access: true },
    verifyEmail: { pathname: '/verify-email', access: true },
  },
};

export const externalRoutes = {
  cdn77public: 'https://www.cdn77.com',
  aboutUs: 'https://www.cdn77.com/about-us',
  privatePolicy: 'https://www.cdn77.com/privacy-policy',
  termsAndConditions: 'https://www.cdn77.com/terms-and-conditions',
  streamingClientLoginPage: 'https://streaming-client.cdn77.com/index.php?page=login',
  createAccount: 'https://www.cdn77.com/signup',
  contact: 'https://www.cdn77.com/contact',
  intercomWidget: 'https://widget.intercom.io/widget',
};

export const endPoints = {
  clap: {
    cdnResource: '/cdn',
    cdnResourcesHistory: '/cdn-history',
    streamResource: '/stream-cdn',
    cdnStorage: '/storage',
    origin: '/origin',
    urlOrigin: '/origin/url',
    awsOrigin: '/origin/aws',
    objectStorageOrigin: '/origin/object-storage',
    rawLogs: '/raw-logs',
    downloadRawLogs: '/raw-logs/download',
    billingInformation: '/invoice/contact-detail',
    countries: '/invoice/country',
    apiToken: '/auth/token',
    spend: '/stats/region/spend',
    internalCustomer: '/customer/internal',
    monthlyPlansList: '/monthly-traffic-plan',
    removeCreditCard: '/payment/remove-info',
    sum: '/sum',
    recipes: '/payment/recipes',
    paymentSettings: '/payment/settings',
    customPlanInfo: '/plan/custom',
    monthlyPlan: '/plan/monthly',
    inquiry: '/inquiry/send',
    customer: '/customer',
    creditHistory: '/customer/credit-operations/history',
    creditBalanceChart: (fromUnix: number, toUnix: number) =>
      `/customer/credit-balance/history?from=${fromUnix}&to=${toUnix}`,
    streamingPanelData: '/customer/streaming-panel-data',
    password: '/customer/password',
    stats: '/stats',
    cacheStats: '/cache-stats',
    teamList: '/customer/team',
    bulkTeamInvite: '/customer/team/bulk-invite',
    credit: '/customer/credit',
    teamInvite: '/customer/team/invite',
    teamMemberSuspend: 'customer/team-member/{id}/suspend',
    teamMemberUnsuspend: 'customer/team-member/{id}/unsuspend',
    mailingUnsubscribe: (customerId: string, hash: string) =>
      `/internal/customer/mailing-unsubscribe/${customerId}/${hash}`,
    sslStatus: (id: string) => `/cdn/${id}/ssl-status`,
    confirmEmail: '/customer/confirm-email',
    teamJoin: '/internal/customer/team/join',
    datacenter: '/datacenter',
    creditCard: '/payment/credit-card',
    auth2fa: '/auth/2fa',
    verifyPassword: '/customer/password/verify',
    storagePlanDetail: '/storage-plan',
    storagePlanUsage: '/storage-plan/usage',
    storagePlanPricing: '/storage-plan/pricing',
    storageLocations: '/storage-location',
    customPriceRates: '/pay-as-you-go/rates',
    sendVerificationEmail: '/customer/resend-verification-email',
    downloadInvoice: '/invoice/download',
    sampleLogs: '/raw-logs/sample',
    ssl: '/ssl',
    statsChart: '/stats/chart',
    cacheStatsChart: '/cache-stats/chart',
    invoices: '/invoice',
    alert: '/alert',
    suspendAccount: '/customer/suspend',
    datacentersStatus: '/datacenter/status',
    signupTeamMember: '/internal/customer/team/invite',
    requestResetPassword: '/customer/password/request-reset',
    resetPassword: '/customer/password/reset',
    verifyCredentials: '/auth/credentials/verify',
    objectStorageClusters: '/object-storage/clusters',
    objectStorageAccessKey: '/object-storage/access-key',
    objectStorageAccessKeys: '/object-storage/access-keys',
    objectStorageQuota: '/object-storage/quota',
    objectStorageStatistics: '/object-storage/stats',
    objectStoragePricing: '/object-storage/pricing',
    objectStorageBilling: '/object-storage/billing',
    userRole: '/customer/role',
    resetSessionToken: '/customer/reset-session-token',
    popularObjects: '/stats/objects/popular',
    confirmTerminationNotice: '/customer/termination-notice/confirm',
    maxAgeList: '/cdn/max-age/values',
  },
  login: '/api/public/login',
  logout: '/api/public/logout',
  join: '/api/public/join',
  date: '/api/public/date',
  downloadRawLog: '/api/download/raw-log',
};
