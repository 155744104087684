import { useRouter } from 'next/router';
import React from 'react';
import { createContainer } from 'unstated-next';

import { getAllRoutesWithAccess } from './getAllRoutesWithAccess';
import { useMonthlyPlanPaidPeriods } from '@components/Pages/Billing/MonthlyPlanPage/useMonthlyPlanPaidPeriods';
import { useDevToolsContext } from '@hooks/useDevToolsContext';
import { useOrigins } from '@hooks/useOrigins';
import { useUser } from '@hooks/useUser';

const useRoutes = () => {
  const router = useRouter();
  const { user } = useUser();
  const withDevTools = useDevToolsContext();
  const { origins } = useOrigins(
    user.isLoggedIn && !router.pathname.startsWith('/support') && user.account_info.email_confirmed,
  );

  const { isLoading } = useMonthlyPlanPaidPeriods();

  return React.useMemo(
    () =>
      getAllRoutesWithAccess({
        user,
        enableStagingPages: withDevTools,
        withStorage: origins ? origins.storage.length > 0 : false,
      }),
    [user, isLoading, origins, router.pathname],
  );
};

export const RoutesContainer = createContainer(useRoutes);
