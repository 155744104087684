import type { ITheme } from '@datacamp/cdn-ui';
import { ThemeContext } from '@datacamp/cdn-ui';
import { useRouter } from 'next/router';
import React from 'react';

import { useLocalStorageState } from '@hooks/useLocalStorageState';
import { getTheme } from '@pages/_document';
import { getCookie } from '@utils/getCookie';

export type AppTheme = ITheme | 'system';

const AppThemeContext = React.createContext<
  [AppTheme, (theme: AppTheme) => void]
  // eslint-disable-next-line @typescript-eslint/no-empty-function
>(['system', () => {}]);

interface Props {
  defaultTheme: ITheme;
  children: React.ReactNode;
}

export const useAppTheme = () => React.useContext(AppThemeContext);

const setThemeToCookie = (theme: ITheme) => {
  document.cookie = `theme=${theme}; expires=${new Date(2147483647 * 1000).toUTCString()}; path=/`;
};

export const AppThemeProvider: React.FC<Props> = ({ defaultTheme, children }) => {
  const router = useRouter();
  const [userTheme, setUserTheme] = useLocalStorageState<AppTheme | null>('app.theme', null);
  const [theme, setTheme] = React.useState<ITheme>(defaultTheme);

  const pageTheme = React.useMemo<{
    bg: ITheme | 'dark-blue' | undefined;
    theme: ITheme | undefined;
  }>(() => {
    const pageTheme = getTheme(router.pathname);

    return { bg: pageTheme, theme: pageTheme === 'dark-blue' ? 'dark' : pageTheme };
  }, [router.pathname]);

  React.useEffect(() => {
    if (theme !== undefined) return;

    const cookieTheme = getCookie('theme');
    if (cookieTheme === 'light' || cookieTheme === 'dark') {
      setTheme(cookieTheme);
    }
    if (cookieTheme === 'system') {
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      setTheme(mql.matches ? 'dark' : 'light');
    }
  }, [theme, router.pathname]);

  const handleUserThemeChange = (theme: AppTheme) => {
    const pageTheme = getTheme(router.pathname);
    const usedTheme = pageTheme === 'dark-blue' ? 'dark' : pageTheme ?? theme;

    if (usedTheme === 'system') {
      const mql = window.matchMedia('(prefers-color-scheme: dark)');
      setThemeToCookie(mql.matches ? 'dark' : 'light');
    } else {
      setThemeToCookie(usedTheme);
    }

    setUserTheme(usedTheme);
  };

  React.useEffect(() => {
    const isDarkBlue = document.documentElement.classList.contains('dark-blue');

    if (!isDarkBlue && localStorage.getItem('app.theme') === null) {
      handleUserThemeChange('system');
    }
  }, []);

  React.useEffect(() => {
    if (userTheme === null || userTheme === 'system') return;

    setTheme(userTheme);
  }, [userTheme]);

  React.useEffect(() => {
    if (userTheme !== 'system') return;

    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    setTheme(mql.matches ? 'dark' : 'light');
    setThemeToCookie(mql.matches ? 'dark' : 'light');

    mql.onchange = e => {
      setTheme(e.matches ? 'dark' : 'light');
      setThemeToCookie(e.matches ? 'dark' : 'light');
    };
  }, [userTheme]);

  React.useEffect(() => {
    if (pageTheme.bg === 'dark-blue') {
      document.documentElement.classList.add('dark-blue');
      document.documentElement.classList.remove('light');
      document.documentElement.classList.remove('dark');
    } else if (pageTheme.theme === 'light' || theme === 'light' || !theme) {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
      document.documentElement.classList.remove('dark-blue');
    } else if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
      document.documentElement.classList.remove('dark-blue');
    }
  }, [theme, pageTheme]);

  return (
    <AppThemeContext.Provider
      value={[pageTheme.theme ?? userTheme ?? 'light', handleUserThemeChange]}
    >
      <ThemeContext.Provider value={pageTheme.theme ?? theme ?? 'light'}>
        {children}
      </ThemeContext.Provider>
    </AppThemeContext.Provider>
  );
};
