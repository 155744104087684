import { captureMessage } from '@sentry/nextjs';
import { useMutation as useReactMutation } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import React from 'react';
import { toast } from 'react-toastify';

import { endPoints } from './Routes/routes';
import { useUser } from './useUser';

// TODO: different way to call get-sums endpoint
export type IClapProxy = 'public' | 'general' | 'get-sums';
export type IMethod = 'POST' | 'GET' | 'PUT' | 'PATCH' | 'DELETE';

interface IClapError {
  description?: string;
  errors: Record<string, string> | string[];
  fields?: Record<string, string>;
  type?: string;
}

interface IParams<TResult, TVariables> {
  method?: IMethod;
  getErrorMessage?: (error: Error) => string;
  excludedErrorTypes?: string[];
  onSuccess?: (result: TResult, variables?: TVariables) => void;
  onError?: (error: { message: string; type?: string }, variables?: TVariables) => void;
  clapProxy?: IClapProxy;
  download?: { filename: string };
}

export const getCsrf = () =>
  document.querySelector("meta[name='csrf-token']")?.getAttribute('content') ?? '';

const isJsonString = (str: string) => {
  try {
    const obj = JSON.parse(str);

    if (obj && typeof obj === 'object') {
      return true;
    }
    return false;
  } catch (e) {
    return false;
  }
};

const isClapError = <T>(data: T | IClapError): data is IClapError =>
  (data as IClapError).errors !== undefined;

export const getMainUrl = (clapProxy?: IClapProxy) => {
  if (clapProxy === 'general') {
    return '/api/public/client';
  }

  return clapProxy ? `/api/client/${clapProxy}` : undefined;
};

let memoizedFormValues = {};

export const useMutation = <
  TResult extends Record<string, unknown>,
  TVariables extends unknown[] | Record<string, unknown> = any,
>(
  {
    method = 'POST',
    getErrorMessage,
    onSuccess,
    onError,
    clapProxy,
    excludedErrorTypes,
    download,
  }: IParams<TResult, TVariables> = {
    method: 'POST',
  },
) => {
  let type: string | undefined;
  let showErrorToast = true;
  const { user } = useUser();
  const router = useRouter();

  const [responseData, setResponseData] = React.useState<TResult | null>(null);

  React.useEffect(() => {
    if (responseData !== null && onSuccess) {
      onSuccess(responseData, memoizedFormValues as TVariables);
    }
  }, [responseData]);

  return useReactMutation<
    Response,
    Error,
    { url: string; variables?: Record<string, unknown> | unknown[]; formValues?: TVariables }
  >({
    mutationFn: inputData => {
      if (
        user.isLoggedIn &&
        !user.editEnabled &&
        inputData.url !== endPoints.logout &&
        clapProxy === 'public'
      ) {
        throw new Error('You do not have permission to perform this action');
      }

      if (inputData.formValues) {
        memoizedFormValues = inputData.formValues;
      }
      const clapProxyUrl = getMainUrl(clapProxy);

      const getBody = () => {
        const getContent = () => {
          if (clapProxyUrl) {
            return {
              method,
              path: inputData.url,
              params: inputData.variables ?? null,
            };
          }

          return inputData.variables;
        };

        return JSON.stringify(getContent());
      };

      return fetch(clapProxyUrl ?? inputData.url, {
        method: clapProxy ? 'POST' : method,
        cache: 'no-cache',
        redirect: 'follow',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          csrf: getCsrf(),
        },
        body: getBody(),
      });
    },
    onSuccess: async response => {
      if (download && response.status < 400) {
        const blob = await response.blob();

        const link = document.createElement('a');
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute('href', url);
          link.setAttribute('download', download.filename);
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }

        return;
      }

      if (response.redirected) {
        void router.push(response.url);
      }

      const responseText = await response.text();
      const data = (isJsonString(responseText) ? JSON.parse(responseText) : {}) as TResult;

      if (data.type && typeof data.type === 'string') {
        // eslint-disable-next-line prefer-destructuring
        type = data.type;

        if (excludedErrorTypes?.includes(data.type)) {
          showErrorToast = false;
        }
      }
      if (isClapError(data)) {
        if (!Array.isArray(data.errors)) {
          throw new Error(Object.values(data.errors)[0]);
        }

        throw new Error(data.fields ? Object.values(data.fields)[0] : data.errors[0]);
      }
      if (response.status >= 400) {
        if (data.error && typeof data.error === 'string') {
          throw new Error(data.error);
        }
        if (data.fields && typeof data.fields === 'object') {
          const [firstValue] = Object.values(data.fields);
          if (typeof firstValue === 'string') {
            throw new Error(firstValue);
          }
          if (Array.isArray(firstValue) && typeof firstValue[0] === 'string') {
            throw new Error(firstValue[0]);
          }
        }
        if (data.message && typeof data.message === 'string') {
          throw new Error(data.message);
        }

        captureMessage(`Unknown error ${response.status}: ${JSON.stringify(data)}`);
        throw new Error('Unknown Error');
      }

      if (onSuccess) {
        setResponseData(data);
      }
    },
    onError: error => {
      if (showErrorToast) {
        toast(getErrorMessage ? getErrorMessage(error) : error.message, { type: 'error' });
      }

      onError?.({ message: error.message, type }, memoizedFormValues as TVariables);
    },
  });
};
